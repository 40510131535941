<template>
    <v-dialog v-model="formDialog" v-if="contract">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">{{ label }}</v-btn>
        </template>
        <v-card>
            <v-card-title>Vyberte šablonu a zkontrolujte odesílané atributy</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <PartnerInfo :item="contract.attributes.client"></PartnerInfo>
                    </v-col>
                    <v-col cols="6">
                        <h3 class="mt-3 mb-3">Fakturační adresa</h3>
                        <table class="partner-table mb-5">
                            <tr><th>Ulice: </th><td>{{ invoicingAddress.street }}</td></tr>
                            <tr><th>Město: </th><td>{{ invoicingAddress.city }}</td></tr>
                            <tr><th>PSČ: </th><td>{{ invoicingAddress.zip }}</td></tr>
                            <tr><th>Země: </th><td>{{ invoicingAddress.country }}</td></tr>
                        </table>
                        <div v-if="client && client.attributes.invoicing_fakturoid_id">
                            Fakturoid Id: {{ client.attributes.invoicing_fakturoid_id }}
                        </div>
                        <div v-else>
                            <v-btn color="primary" dark class="mb-2" :disabled="registering_client" @click.prevent="registerClient(client)">Registorvat zákazníka ve fakturoidu</v-btn>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-select
                                v-model="generator"
                                :items="generators"
                                :loading="isLoading"
                                label="Šablona"
                                item-text="name"
                                item-value="id"
                                placeholder="Vyberte..."
                                return-object
                                outlined
                                hide-details
                                dense
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row v-if="generator">
                    <v-col cols="12">
                        <v-row>
                            <!-- <v-col cols="4">
                                <v-select
                                    v-model="theme"
                                    :items="invoiceThemes"
                                    label="Vzhled"
                                    item-text="title"
                                    item-value="name"
                                    outlined
                                    hide-details
                                    dense
                                    class="mb-3"
                                ></v-select>
                            </v-col> -->
                            <v-col cols="4">
                                <v-checkbox v-model="transferred_tax_liability" label="Přenesená DPH" class="mt-0 pt-2"></v-checkbox>
                            </v-col>
                            <v-col v-if="transferred_tax_liability" cols="8">
                                <v-select
                                    v-model="supply_code"
                                    :items="supplyCodes"
                                    label="Kód plnění"
                                    item-text="title"
                                    item-value="code"
                                    outlined
                                    hide-details
                                    dense
                                    class="mb-3"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="bankAccount"
                                :items="bankAccounts"
                                :loading="isLoadingBankAccount"
                                label="Bankovní účet"
                                item-text="name"
                                item-value="id"
                                placeholder="Vyberte..."
                                return-object
                                outlined
                                hide-details
                                dense
                                class="mb-3"
                        ></v-select>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-model="bankAccount.id" label="ID Bankovního účtu" disabled></TextField>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-model="bankAccount.number" label="Číslo bankovního účtu" disabled></TextField>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-model="bankAccount.iban" label="IBAN" disabled></TextField>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-model="bankAccount.swift_bic" label="SWIFT BIC" disabled></TextField>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-if="generator && generator !== true" v-model="generator.payment_method" label="Platební metoda" disabled></TextField>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-model="bankAccount.currency" label="Měna" disabled></TextField>
                    </v-col>
                    <v-col cols="6">
                        <DateTimePicker :datetime="issued_on" label="Datum vystavení" @input="issued_on = $event" :usetime="false"></DateTimePicker>
                    </v-col>
                    <v-col cols="6">
                        <DateTimePicker :datetime="taxable_fulfillment_due" label="Datum zdanitelného plnění" @input="taxable_fulfillment_due = $event" :usetime="false"></DateTimePicker>
                    </v-col>
                    <v-col cols="12">
                        <TextField v-model="due" label="Splatnost" type="number"></TextField>
                    </v-col>
                    <v-col cols="12">
                        <TextField v-model="order_number" label="Číslo objednávky"></TextField>
                    </v-col>
                    <v-col v-if="lines" v-for="(line, idx) in lines" cols="12">
                        <v-row>
                            <v-col cols="5">
                                <TextArea v-model="line.name" label="Název"></TextArea>
                            </v-col>
                            <v-col cols="2">
                                <TextField v-model="line.quantity" label="Množství"></TextField>
                            </v-col>
                            <v-col cols="2">
                                <TextField v-model="line.vat_rate" label="DPH"></TextField>
                            </v-col>
                            <v-col cols="2">
                                <TextField v-model="line.unit_price" label="Cena za jednotku"></TextField>
                            </v-col>
                            <v-col cols="1">
                                <v-btn v-if="idx > 0" color="primary" dark class="mb-2" @click.prevent="removeLine(idx)" :disabled="!!line.id"> - </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-btn color="primary" dark class="mb-2" @click.prevent="addLine()">Přidat řádek</v-btn>
                    </v-col>
                </v-row>

                <v-row wrap @drop.prevent="addNewAttachment" @dragenter.prevent="dragEnter = true" @dragover.prevent="dragEnter = true" @dragleave.prevent="dragEnter = false" @dragend.prevent="dragEnter = false" :class="{ dragging: dragEnter }">
                    <v-col v-if="newAttachment" cols="12">
                        <v-icon @click.prevent="deleteNewAttachment" small>mdi-delete</v-icon>
                        {{ newAttachment.name }} ({{ (newAttachment.size / 1024 / 1024).toFixed(4) }} MB)
                    </v-col>
                    <v-col cols="12">
                        <v-btn color="primary" dark @click.prevent="addNewAttachment">Přiložit Soubor</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="validData"
                       :disabled="creating_invoice" :loading="creating_invoice"
                       color="primary" dark class="mb-2" @click.prevent="createInvoice">Fakturovat</v-btn>
                <span v-else>{{ invalidMessage }}</span>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import date from '../mixins/date';
    import supply_codes from '../mixins/supplyCodes';
    import TextField from "./TextField.vue";
    import TextArea from "./TextArea.vue";
    import PartnerInfo from "./PartnerInfo.vue";
    import Partner from "../models/Partner";
    import Invoice from "../models/Invoice";
    import DateTimePicker from "./DateTimePicker.vue";
    import utils from "../mixins/utils";

    export default {
        name: "InvoiceDialog",
        components: {DateTimePicker, PartnerInfo, TextField, TextArea},
        mixins: [date, supply_codes, utils],
        props: {
            contract: { required: true },
            label: { required: false, default: 'Fakturovat' }
        },

        data: () => {
            return {
                formDialog: false,

                isLoading: false,
                generator: null,
                generators: [],

                isLoadingBankAccount: false,
                bankAccount: {},
                bankAccounts: [],

                issued_on: null,
                taxable_fulfillment_due: null,
                transferred_tax_liability: false,
                supply_code: null,
                // theme: 'solaris',
                due: null,
                order_number: null,
                lines: null,
                client: null,

                registering_client: false,
                creating_invoice: false,

                dragEnter: false,
                newAttachment: null,

                invalidMessage: null,

                invoice: {},
            }
        },

        mounted() {
        },

        computed: {
            invoicingAddress() {
                if (!this.client) return {};

                const iaddress = this.client.invoicingAddress();
                if (!iaddress) return {};

                return iaddress;
            },

            validData() {
                if (!this.client || !this.client.attributes.invoicing_fakturoid_id) {
                    this.invalidMessage = 'Zákazník není zaregistrován ve fakturoidu.';
                } else if (!this.generator) {
                    this.invalidMessage = 'Musíte vybrat šablonu.';
                } else if (this.lines.find(l => l.unit_price === null || l.unit_price === '')) {
                    this.invalidMessage = 'Zadejte cenu.';
                } else if (this.lines.find(l => l.name === null || l.name === '')) {
                    this.invalidMessage = 'Zadejte název.';
                } else if (this.lines.find(l => l.quantity === null || l.quantity === '')) {
                    this.invalidMessage = 'Zadejte množství.';
                } else if (this.lines.find(l => l.vat_rate === null || l.vat_rate === '')) {
                    this.invalidMessage = 'Zadejte DPH.';
                } else {
                    this.invalidMessage = null;
                }

                return !this.invalidMessage;
            },
        },

        watch: {
            formDialog(value) {
                if (value !== true) {
                    return;
                }

                this.client = new Partner(this.contract.attributes.client);
                this.client.fetch();

                if (this.contract.attributes.invoice) {
                    this.issued_on = this.contract.attributes.invoice.data.issued_on;
                    this.taxable_fulfillment_due = this.contract.attributes.invoice.data.taxable_fulfillment_due;
                    this.transferred_tax_liability = this.contract.attributes.invoice.data.transferred_tax_liability;
                    this.supply_code = this.contract.attributes.invoice.data.supply_code;
                    // this.theme = this.contract.attributes.invoice.data.theme;
                    this.due = this.contract.attributes.invoice.data.due;
                    this.order_number = this.contract.attributes.invoice.data.order_number;
                    this.lines = [];
                    this.contract.attributes.invoice.data.lines.forEach(line => this.lines.push(Object.assign({}, line)));
                }

                this.isLoading = true;
                this.contract.invoiceGenerators()
                    .then(templates => {
                        this.generators = templates;
                        if (this.contract.attributes.invoice) {
                            this.generator = this.generators.find(g => g.id === this.contract.attributes.invoice.data.generator_id);
                            if (this.generator === null || this.generator === undefined) {
                                this.generator = true;
                            }
                        }

                        // load and set bank accounts
                        this.isLoadingBankAccount = true;
                        this.contract.invoiceBankAccounts()
                            .then(accounts => {
                                this.bankAccounts = accounts.filter(a => a.number !== null);
                                this.setBankAccount(this.generator, false);
                            })
                            .finally(() => {
                                this.isLoadingBankAccount = false;
                            });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            generator(value) {
                if (!this.isLoading) {
                    this.updateData(value);
                }
            },
        },

        methods: {
            setBankAccount(generator, generator_first) {
                if (!generator_first && this.contract.attributes.invoice && this.contract.attributes.invoice.data.bank_account_id) {
                    const bank_account_id = this.contract.attributes.invoice.data.bank_account_id;
                    this.bankAccount = this.bankAccounts.find(a => a.id === bank_account_id);
                } else if (generator && generator !== true) {
                    if (generator.bank_account_id) {
                        this.bankAccount = this.bankAccounts.find(a => a.id === generator.bank_account_id);
                    } else if (generator.currency) {
                        this.bankAccount = this.bankAccounts.find(a => a.currency === generator.currency);
                    }
                }
            },

            addLine() {
                this.lines.push({
                    name: null,
                    quantity: 1,
                    vat_rate: 21,
                    unit_price: null,
                });
            },

            removeLine(idx) {
                if (this.lines[idx].id) return;

                this.lines.splice(idx, 1);
            },

            addNewAttachment(e) {
                if (this.dragEnter) {
                    this.dragEnter = false;
                    let dt = e.dataTransfer;
                    let files = dt.files;
                    this.newAttachment = [...files][0];
                    this.attachmentToInvoice();
                } else {
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.onchange = () => {
                        this.newAttachment = input.files[0];
                        this.attachmentToInvoice();
                    };
                    input.click();
                }
            },

            attachmentToInvoice() {
                if (this.newAttachment) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      this.invoice.attachment = e.target.result;
                    };
                    reader.readAsDataURL(this.newAttachment);
                } else {
                    this.invoice.attachment = null;
                }
            },

            deleteNewAttachment() {
                this.newAttachment = null;
                this.attachmentToInvoice();
            },

            updateData(gen) {
                this.due = gen.due;
                this.order_number = this.contract.attributes.number;
                this.taxable_fulfillment_due = this.formatDateYMD(this.contract.attributes.unloadings[this.contract.attributes.unloadings.length - 1].time_to);

                this.setBankAccount(gen, true);

                let name = gen.lines[0].name;
                name = name.replace('/datum dodání/', this.formatDateOnly(this.contract.attributes.unloadings[0].time_from));
                name = name.replace('/město nakl/', this.contract.attributes.loadings[0].place.city);
                name = name.replace('/firma nakl/', this.contract.attributes.loadings[0].place.name);
                name = name.replace('/město vykl/', this.contract.attributes.unloadings[this.contract.attributes.unloadings.length - 1].place.city);
                name = name.replace('/firma vykl/', this.contract.attributes.unloadings[this.contract.attributes.unloadings.length - 1].place.name);
                name = name.replace('/první řádek z "zboží"/', this.contract.attributes.goods.split('\n')[0].trim());
                name = name.replace('/SPZ/', this.contract.attributes.carrier_contracts.map(carrier => carrier.spz).join(', '));
                name = name.replace('/pozice/', this.contract.attributes.carrier_contracts.map(carrier => carrier.order_number).join(', '));

                const quantity = gen.lines[0].quantity;
                const vat_rate = gen.lines[0].vat_rate;
                const unit_price = this.contract.attributes.price_cents / 100.0;

                let first_line_id = null;
                if (!this.lines || this.lines.length === 0) {
                    this.lines = [{}];
                } else {
                    first_line_id = this.lines[0].id;
                }
                this.lines[0] = {
                    name: name,
                    quantity: quantity,
                    vat_rate: vat_rate,
                    unit_price: unit_price,
                };
                if (first_line_id !== null) {
                    this.lines[0].id = first_line_id;
                }
            },

            createInvoice() {
                this.creating_invoice = true;

                let data = {};
                if (this.generator !== true) {
                    data = JSON.parse(JSON.stringify(this.generator));
                    data.generator_id = this.generator.id;
                }

                if (this.bankAccount && this.bankAccount.id) {
                    data.bank_account_id = this.bankAccount.id;
                    data.bank_account = this.bankAccount.number;
                    data.iban = this.bankAccount.iban;
                    data.swift_bic = this.bankAccount.swift_bic;
                    data.currency = this.bankAccount.currency;
                }

                data.subject_id = this.client.attributes.invoicing_fakturoid_id;
                data.issued_on = this.issued_on;
                data.taxable_fulfillment_due = this.taxable_fulfillment_due;
                data.transferred_tax_liability = this.transferred_tax_liability;
                data.supply_code = this.supply_code;
                // data.theme = this.theme;
                data.due = this.due;
                data.order_number = this.order_number;
                data.lines = this.lines;
                data.lines.forEach((line) => {
                    line.unit_price = this.priceReplaceCommaWithDot(line.unit_price);
                });

                if (this.invoice.attachment) {
                    data.attachment = this.invoice.attachment;
                }
                // console.log(data);

                let invoice = null;
                if (this.contract.attributes.invoice) {
                    invoice = new Invoice({
                        id: this.contract.attributes.invoice.id,
                        contract_id: this.contract.attributes.id,
                        attachment: this.newAttachment,
                        data
                    });
                } else {
                    invoice = new Invoice({
                        contract_id: this.contract.attributes.id,
                        attachment: this.newAttachment,
                        data
                    });
                }

                invoice.save(true)
                    .then((i) => {
                        this.$emit('invoice-created', i);
                        this.formDialog = false;
                    })
                    .finally(() => {
                        this.creating_invoice = false;
                    });
            },

            registerClient(client) {
                this.registering_client = true;
                client.registerInFakturoid('customer').finally(() => {
                    this.registering_client = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>

