<template>
    <v-card v-if="contract" text>
        <v-card-title v-if="titleEnabled" :class="{ closed: contract.isClosed() }">
            <h1>Zakázka {{ contract.attributes.number }}</h1>
            <v-spacer></v-spacer>
            <span v-if="!contract.isLocked()">
                <v-icon v-if="contract.canEdit()" class="mr-2" @click="$router.push(buildUrl(['contracts', contract.attributes.id, 'edit']))">mdi-pencil</v-icon>
                <v-icon v-if="!contract.isClosed()" class="mr-2" @click="contract.close()" alt="Uzavřít" title="Uzavřít">mdi-lock-open-variant</v-icon>
                <v-icon v-else @click="contract.open()" class="mr-2" alt="Otevřít" title="Otevřít">mdi-lock</v-icon>
                <v-icon v-if="contract.canEdit()" @click="deleteContract()">mdi-delete</v-icon>
            </span>
            <ContractFormDialog v-model="contract" :dialog="dialog" :new-enabled="false"
                                @open="dialog = true" @close="dialog = false" @saved=""></ContractFormDialog>
        </v-card-title>
        <v-card-text>
            <v-row v-if="contract.isLocked()" class="locked pa-3" :style="{ 'background-color': contract.backgroundColor($vuetify.theme.dark) }">
                <v-col cols="8" class="d-flex align-center">
                    Zakázka je právě editována uživatelem &nbsp; <b>{{ contract.attributes.locked_by.name }}</b>
                </v-col>
                <v-col cols="4" class="text-right">
                    <v-btn @click="forceUnlock(contract)">Násilím zpřístupnit</v-btn>
                </v-col>
            </v-row>
            <v-row wrap>
                <v-col>
                    <span v-if="contract.attributes.created_by"><b>Vyřizuje: </b>{{ contract.attributes.created_by.name}}</span>
                </v-col>
                <v-col>
                    <b>Cena zakázky: </b>{{ contract.attributes.price_formatted }}&nbsp;{{ contract.attributes.price_currency }}
                </v-col>
                <v-col v-if="contract.attributes.price_currency !== 'CZK'">
                  <b>Cena v CZK: </b>{{ contract.attributes.price_czk }}
                </v-col>
                <v-col>
                    <b>Pozn. k ceně: </b>
                    <span :class="{ notes_present: (contract.attributes.price_notes !== null && contract.attributes.price_notes != '') }">{{ contract.attributes.price_notes }}</span>
                </v-col>
                <v-col>
                    <b>Cena dopravců: </b> {{ carrierPrices }}&nbsp;CZK
                </v-col>
                <v-col>
                    <b>Cena dopravců: </b> {{ carrierPricesEur }}&nbsp;EUR
                </v-col>
                <v-col>
                    <b>Zisk: </b> {{ profit }} CZK
                </v-col>
                <v-col>
                    <b>Číslo zakázky: </b> {{ contract.attributes.number }}
                </v-col>
            </v-row>
            <v-row wrap>
                <v-col cols="12" sm="6">
                    <table class="contract-detail slim-row">
                        <tr v-if="createdAtEnabled">
                            <th>Vytvořeno:</th>
                            <td class="align-end">{{ formatDate(contract.attributes.created_at) }}</td>
                        </tr>
<!--                        <tr v-if="contract.attributes.created_by">-->
<!--                            <th>Vyřizuje:</th>-->
<!--                            <td class="align-end">{{ contract.attributes.created_by.name }}</td>-->
<!--                        </tr>-->
                        <tr>
                            <th>Zákazník:</th>
                            <td class="align-end">
                                <PartnerInfo v-if="titleEnabled" :item="contract.attributes.client"></PartnerInfo>
                                <span v-else-if="contract.attributes.client">{{ contract.attributes.client.name }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>Kontaktní osoba zákazníka:</th>
                            <td class="align-end">
                                <ContactInfo :item="contract.attributes.client_contact"></ContactInfo>
                            </td>
                        </tr>
                        <tr v-if="titleEnabled">
                            <th>Poznámka k zákazníkovi:</th>
                            <td class="align-end">{{ contract.attributes.client_notes }}
                            </td>
                        </tr>
                        <tr v-if="priceCzkEnabled">
                            <th>Cena zakázky v CZK:</th>
                            <td class="align-end">
                                {{ contract.attributes.price_czk }}
                            </td>
                        </tr>
                        <tr v-if="loadingEnabled" v-for="loading in contract.attributes.loadings">
                            <th>Nakládka:</th>
                            <td class="align-end">
                                <ContractPlaceInfo :item="loading"></ContractPlaceInfo>
                            </td>
                        </tr>
                        <tr v-if="unloadingEnabled" v-for="unloading in contract.attributes.unloadings">
                            <th>Vykládka:</th>
                            <td class="align-end">
                                <ContractPlaceInfo :item="unloading"></ContractPlaceInfo>
                            </td>
                        </tr>
                        <tr>
                            <th>Zboží:</th>
                            <td class="align-end"><pre style="white-space: break-spaces;">{{ contract.attributes.goods }}</pre></td>
                        </tr>
                        <tr :class="{ notes_present: (contract.attributes.notes !== null && contract.attributes.notes != '') }">
                            <th>Poznámky:</th>
                            <td class="align-end">{{ contract.attributes.notes }}</td>
                        </tr>
                        <tr>
                            <th>Dokumenty:</th>
                            <td><DocumentLinkList :documents="contract.attributes.documents"></DocumentLinkList></td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" sm="6">
                    <table class="contract-detail slim-row">
                        <tr>
                            <th>Vzdálenost:</th>
                            <td>{{ (contract.attributes.distance ? contract.attributes.distance : 0) / 1000 }}KM</td>
                        </tr>
                        <tr>
                            <th>Cena za KM:</th>
                            <td>{{ contract.attributes.price_km_czk }} CZK</td>
                        </tr>
                        <tr>
                            <th>Fakturace zakázky:</th>
                            <td>
                                <div v-if="!contract.isLocked() && !contract.attributes.invoice">
                                    <InvoiceDialog :contract="contract" @invoice-created="setInvoice"></InvoiceDialog>
                                </div>
                                <div v-if="contract.attributes.invoice">
                                    Faktura: {{ contract.attributes.invoice.variable_symbol }} ({{ formatDate(contract.attributes.invoice.updated_at) }})
                                    <a :href="buildUrl(['api', 'invoices', contract.attributes.invoice.id, 'download'])"
                                       target="_blank">Stáhnout</a>
                                    <a v-if="contract.attributes.invoice.html_url"
                                       :href="contract.attributes.invoice.html_url"
                                       target="_blank">Přejít</a>

                                    <div v-if="contract.attributes.invoice.attachments && contract.attributes.invoice.attachments.length > 0">
                                        Přílohy:
                                        <ul>
                                            <li v-for="attachment in contract.attributes.invoice.attachments">
                                                <a :href="buildUrl(['api', 'attachments', attachment.id, 'download'])"
                                                    target="_blank">{{ attachment.file_name }}</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <InvoiceDialog :contract="contract" @invoice-created="setInvoice" label="Upravit fakturu"></InvoiceDialog>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="carrier in contract.attributes.carrier_contracts">
                            <th>Dopravce:</th>
                            <td class="align-end">
                                <CarrierInfo :item="carrier" :contract="contract" :short="!titleEnabled"></CarrierInfo>
                            </td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import CarrierInfo from './CarrierInfo';
    import ContactInfo from './ContactInfo';
    import PartnerInfo from './PartnerInfo';
    import PlaceInfo from './PlaceInfo';
    import Contract from "../models/Contract";
    import date from '../mixins/date';
    import ContractFormDialog from "./ContractFormDialog";
    import InvoiceDialog from "./InvoiceDialog.vue";
    import ContractPlaceInfo from "./ContractPlaceInfo";
    import DocumentLinkList from "./DocumentLinkList";
    import utils from "../mixins/utils";

    export default {
        name: "ContractView",

        mixins: [date, utils],
        
        props: {
            item: {},
            loadingEnabled: { default: true },
            unloadingEnabled: { default: true },
            numberEnabled: { default: true },
            createdAtEnabled: { default: true },
            titleEnabled: { default: true },
            priceCzkEnabled: { default: true },
            fetchContractEnabled: { default: true }
        },

        components: {ContractPlaceInfo, ContractFormDialog, CarrierInfo, ContactInfo, PartnerInfo, PlaceInfo, DocumentLinkList, InvoiceDialog},

        data: () => {
            return {
                dialog: false,
                contract: null
            };
        },

        created() {
            this.contract = this.item;
        },

        mounted() {
            this.$nextTick(function () {
                if (this.fetchContractEnabled) this.fetchContract();
            });
        },

        beforeRouteEnter(to, from, next) {
            if (from.params.action === 'edit' && from.params.id === to.params.id) {
                let item = new Contract({ id: from.params.id });
                item.unlock().finally(() => { next() });
            } else {
                next();
            }
        },

        computed: {
            carrierPrices() {
                // let price_czk = 0;
                // this.contract.attributes.carrier_contracts.forEach((carrier) => {
                //     if (carrier.price_czk) {
                //         let price = carrier.price_czk.replace(',', '.');
                //         price_czk = price_czk + Number.parseFloat(price);
                //     }
                // });
                //
                // let price_str = "" + price_czk;
                // return price_str.replace('.', ',');
                return this.contract.attributes.carrier_prices;
            },

            carrierPricesEur() {
                return this.contract.attributes.carrier_prices_eur;
            },

            profit() {
                // let price_czk = Number.parseFloat(this.contract.attributes.price_czk.replace(',', '.'));
                // let price_carriers_czk = Number.parseFloat(this.carrierPrices.replace(',', '.'));
                // let diff = price_czk - price_carriers_czk;
                // if (Number.isNaN(diff)) {
                //     return '';
                // }
                // let diff_str = diff.toFixed(2);
                // return diff_str.replace('.', ',');
                return this.contract.attributes.profit;
            }
        },

        methods: {
            deleteContract() {
                if (confirm('Opravdu smazat zakázku?')) {
                    this.contract.delete()
                        .then(() => {
                            this.$router.push({ name: 'contracts' });
                        });
                }
            },

            fetchContract() {
                if (!this.contract ||
                    (this.$route.params.id && this.contract.attributes.id !== this.$route.params.id)) {
                    let contract = new Contract({id: this.$route.params.id});
                    contract.fetch().then(() => {
                        this.contract = contract;
                    });
                }
            },

            setInvoice(i) {
                let contract = new Contract({id: this.contract.attributes.id});
                contract.fetch().then(() => {
                    this.contract = contract;
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .contract-detail {
        tr {
            padding-bottom: 1rem;

            /*:hover {*/
            /*    background-color: #EFEFEF;*/
            /*}*/

            td, th {
                vertical-align: top;
                border: none !important;
            }

            th {
                width: 10rem;
                text-align: right;
                padding-right: 1rem;
            }
        }
    }
</style>
